import React, { useEffect, useState } from 'react'
import { send_get_request } from '../utils/db'
import { AdminReadDatasetUrl } from '../config/Constants'
import { Box, Grid, Typography } from '@mui/material'
import { mainTheme_primary } from './template/Colors'
import CustomButton from './components/universal/CustomButton'
import AddIcon from '@mui/icons-material/Add';
import AddDatasetModal from './components/AddDatasetModal';

const ManageDataset = () => {

    const [datasetList, setDatasetList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);

    const searchDataset = async() => {
        await send_get_request(AdminReadDatasetUrl).then(result => {
            setDatasetList(result.result)
        })
    }

    useEffect(() => {
        searchDataset();
    }, [])


    return (
        <React.Fragment>
            <Grid container spacing={2} maxWidth='lg' sx={{ marginRight: 'auto', marginLeft: "auto" }}>

                <Grid item xs={12}>
                    <Typography sx={{ fontWeight: '800' }}>Dataset list</Typography>
                </Grid>


                <Grid item xs={12}>
                    {datasetList.map((item, index) => {
                        return (
                            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', border: 1, marginBottom:2, padding: 2, borderRadius: 2, borderColor: mainTheme_primary.gray_border }}>
                                <Box>
                                    <Typography>{item.name}</Typography>
                                </Box>
                            </Box>
                        )
                    })}
                </Grid>


                <Grid item xs={12}>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CustomButton
                            text="Add"
                            endIcon={<AddIcon />}
                            onClick={() => setModalOpen(true)}
                        />
                    </Box>
                </Grid>


                <AddDatasetModal 
                    openModal={modalOpen}
                    handleClose={() => setModalOpen(false)}
                    searchDataset={searchDataset}
                />

            </Grid>
        </React.Fragment>
    )
}

export default ManageDataset