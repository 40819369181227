import React, { useEffect, useState } from 'react'
import { send_post_request } from '../utils/db'
import { AdminReadErrorLogs } from '../config/Constants'
import { Grid, LinearProgress, Box, Stack, Pagination } from '@mui/material'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';


const LIMIT_RECORDS = 10


const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));



const BugReport = () => {

    const [loading, setLoading] = useState(false);
    const [resultList, setResultList] = useState([]);
    const [totalRows, setTotalRows] = useState(0);


    const searchError = (offset) => {
        setLoading(true);

        const data = {
            "offset": offset,
            "number": LIMIT_RECORDS
        }

        send_post_request(data, AdminReadErrorLogs, true).then(result => {
            setLoading(false)
            setResultList(result.result.list);
            setTotalRows(Math.ceil(result.result.rows / LIMIT_RECORDS));
        })
    }


    useEffect(() => {
        searchError(0);
    }, [])


    const paginationHandler = (event, val) => {
        // setOffset(val - 1);
        searchError((val - 1) * LIMIT_RECORDS);
      }


    return (
        <React.Fragment>
            <Grid container spacing={2} maxWidth='lg' sx={{ marginRight: 'auto', marginLeft: "auto" }}>
                {loading &&
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                }


                {!loading &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table" size='small'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>Row</StyledTableCell>
                                            <StyledTableCell>Time</StyledTableCell>
                                            <StyledTableCell>Message</StyledTableCell>
                                            <StyledTableCell align="left">Function</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {resultList.map((row, index) => (
                                            <TableRow
                                                key={row.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="left">{index + 1}</TableCell>
                                                <TableCell align="left">{row.timestamp}</TableCell>
                                                <TableCell align="left">{row.message}</TableCell>
                                                <TableCell align="left">{row.function}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>


                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                                <Stack spacing={2} direction='row'>
                                    <Pagination count={totalRows} onChange={(event, val) => paginationHandler(event, val)} variant="outlined" shape="rounded" />
                                </Stack>
                            </Box>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </React.Fragment>
    )
}

export default BugReport