import { Box } from '@mui/material';
import { Stack } from '@mui/system';
import React, { useState } from 'react'
import Cropper from 'react-easy-crop';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import CustomButton from './universal/CustomButton';


const CropImage = ({ image, onCropDone, onCropCancel }) => {

    const imageUrl = URL.createObjectURL(image);

    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [cropedArea, setCropedArea] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(4 / 3);

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixles) => {
        setCropedArea(croppedAreaPixles);
    }

    const cancelHandler = () => { }
    const onAaspectRatioChange = (event) => {
        setAspectRatio(event.target.value);
    }

    return (
        <Box sx={ComponentStyle.mainContainer}>
            <Box>
                <Cropper
                    image={imageUrl}
                    aspect={aspectRatio}
                    crop={crop}
                    zoom={zoom}
                    onCropChange={setCrop}
                    onZoomChange={setZoom}
                    onCropComplete={onCropComplete}
                    style={{
                        containerStyle: {
                            // width:"100%",
                            height: "78%",
                            backgroundColor: "#fff",
                            display: 'flex'
                        }
                    }}
                />
            </Box>

            <Box sx={{ marginTop: 46, justifyContent: "center", alignItems: 'center', display: 'flex' }}>
                <FormControl>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={onAaspectRatioChange}
                    >
                        <FormControlLabel value={1 / 1} control={<Radio />} label="1:1" />
                        <FormControlLabel value={5 / 4} control={<Radio />} label="5:4" />
                        <FormControlLabel value={4 / 3} control={<Radio />} label="4:3" />
                        <FormControlLabel value={3 / 2} control={<Radio />} label="3:2" />
                        <FormControlLabel value={5 / 3} control={<Radio />} label="5:3" />
                        <FormControlLabel value={16 / 9} control={<Radio />} label="16:9" />

                    </RadioGroup>
                </FormControl>
            </Box>

            <Box sx={{display:'flex', justifyContent:"center", padding:2}}>
                <Stack direction='row' spacing={1}>
                    <CustomButton
                        text="Cancel"
                        onClick={cancelHandler}
                    />

                    <CustomButton
                        text="Apply and Select"
                        onClick={() => {onCropDone(cropedArea)}}
                    />
                </Stack>
            </Box>
        </Box>
    )
}

export default CropImage


const ComponentStyle = {
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        height: 435,
    }
}