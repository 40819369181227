import React from 'react';

import { Outlet } from 'react-router-dom';
import Wrapper from './components/Wrapper';

function AdminTemplate() {

    return (
        <React.Fragment>
            <React.Fragment>
                <Wrapper>
                    <Outlet />
                </Wrapper>
            </React.Fragment>
        </React.Fragment>
    )
}

export default AdminTemplate