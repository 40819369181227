export const mainTheme_primary = {
    // main: '#233044',
    // mainLight: '#42536e',
    white: '#fff',
    black:"#000",
    gray:'#d7d7d7',
    gray_2:"#f2f2f2",
    gray_3:"#8c8c8c",
    gray_border:'#d4d4d4',

    primary:"#1f3851",
    seccondary:"#FEE715",

    greenMain:"#4caf50",
    greenUltraLight:"#c8e6c9",

    redMain:"#f44336",
    redUltraLight:"#ffcdd2",

    main:'#3f51b5',
    dark:"#1a237e",
    light:"#c5cae9",
  }
  