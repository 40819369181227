import React from 'react'
import { Button } from '@mui/material'
import { mainTheme_primary } from '../../template/Colors';

const CustomButton = ({ onClick, startIcon, endIcon, text, color, backgroundColor, hoverColor, fullWidth, id, size, type, disabled }) => {
    return (
        <Button
            size={size}
            id={id}
            disabled={disabled}
            onClick={onClick}
            variant="contained"
            startIcon={startIcon}
            endIcon={endIcon}
            type={type}
            sx={{
                textTransform: 'none',
                color: color ? color : mainTheme_primary.white,
                backgroundColor: backgroundColor ? backgroundColor : mainTheme_primary.primary,
                '&:hover': { backgroundColor: hoverColor ? hoverColor : mainTheme_primary.mainLight }
            }}
            fullWidth={fullWidth}

        >{text}</Button>
    )
}

export default CustomButton


