import React, { useEffect, useState } from 'react'
import { Modal, Box, Grid, createFilterOptions, TextField } from '@mui/material';
import CustomTextField from './universal/CustomTextField';
import CustomSelect from './universal/CustomSelect';
import CustomButton from './universal/CustomButton';
import CustomAutocomplete from './universal/CustomAutocomplete';


const OPTIONS_LIMIT = 20;

const AddNewIngModal = ({ openModal, handleClose, unit_list, addIngToList, ingredients }) => {


    const filter = createFilterOptions();



    const [ingQuantity, setIngQuantity] = useState('');
    const [ingUnit, setIngUnit] = useState('');
    const [ing, setIng] = useState({});


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        maxWidth: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: 4,
        maxHeight: '90vh',
        display: 'flex',
    };


    const addItemHandler = () => {
        const newItem = { "quantity": ingQuantity, "name": ing.name, "unit_id": ingUnit, "unit": unit_list[ingUnit - 1].name, "id":ing.id }
        addIngToList(newItem);
        handleClose();
    }


    const unitsHandler = (event) => {
        setIngUnit(event.target.value);
    }


    useEffect(() => {
        if (openModal) {
            setIng({});
            setIngQuantity('');
            setIngUnit('');
        }
    }, [openModal])


    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>

                    <Box sx={{ flex: 1, overflow: 'auto', padding: 1 }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomAutocomplete

                                    multiple={false}
                                    limitTags={4}
                                    value={ing ? ing : ''}
                                    options={ingredients}
                                    getOptionLabel={(option) => {
                                        return (option.name || "")
                                    }}
                                    onChange={(event, newValue) => {
                                        setIng(newValue);

                                        // eslint-disable-next-line
                                        if (newValue.id === "new_id") {

                                            const title = newValue.name;
                                            const search_title = title.includes('Add');
                                            if (search_title) {
                                                const item_title = title.split("Add ")[1];
                                                const new_title = item_title.replace(/['"]+/g, '');
                                                newValue.name = new_title
                                            }

                                        }

                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        if (params.inputValue !== '') {
                                            filtered.push({
                                                id: "new_id",
                                                name: `Add "${params.inputValue}"`,
                                            });
                                        }

                                        return filtered.slice(0, OPTIONS_LIMIT);;
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label='ingredients'
                                            placeholder="ingredients"
                                        />
                                    )}
                                />
                            </Grid>


                            <Grid item xs={12}>
                                <CustomTextField
                                    fullWidth={true}
                                    value={ingQuantity}
                                    label="Ingredients Quantity"
                                    onChange={(event) => setIngQuantity(event.target.value)}
                                    type='number'
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomSelect
                                    label="Ingredients Unit"
                                    item_list={unit_list}
                                    handleChange={unitsHandler}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomButton
                                    fullWidth={true}
                                    text="Add"
                                    onClick={addItemHandler}
                                />
                            </Grid>
                        </Grid>

                    </Box>

                </Box>

            </Modal>
        </React.Fragment>
    )
}

export default AddNewIngModal