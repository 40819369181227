import React, { useState } from 'react'
import { Modal, Box, Grid } from '@mui/material';
import CustomTextField from './universal/CustomTextField';
import CustomButton from './universal/CustomButton';
import AddIcon from '@mui/icons-material/Add';
import { send_post_request } from '../../utils/db';
import { AdminAddDataset } from '../../config/Constants';


const AddNewIngModal = ({ openModal, handleClose, searchDataset }) => {

    const [name, setName] = useState('');

    const addHandler = () => {
        if (name) {
            const data = {"name":name}
            send_post_request(data,AdminAddDataset,true).then(result => {
                searchDataset().then(() => {
                    handleClose();
                })
            })
        }
    }


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        maxWidth: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: 4,
        maxHeight: '90vh',
        display: 'flex',
    };


    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>

                    <Box sx={{ flex: 1, overflow: 'auto', padding: 1 }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <CustomTextField
                                    label="dataset name"
                                    fullWidth={true}
                                    value={name}
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomButton
                                    text="add"
                                    endIcon={<AddIcon />}
                                    fullWidth={true}
                                    onClick={addHandler}
                                />
                            </Grid>
                        </Grid>

                    </Box>

                </Box>

            </Modal>
        </React.Fragment>
    )
}

export default AddNewIngModal