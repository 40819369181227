import React from 'react'
import { TextField } from '@mui/material';

const CustomTextarea = ({fullWidth, label, error, value, onChange, Limit, placeholder, height}) => {

    return (
        <TextField 
            fullWidth={fullWidth}
            label={label}
            error={error}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            multiline
            InputProps={{
              inputProps: {
                maxLength: Limit,
                style: { height: height, fontSize:"14px"}
              },
            }}
        />
    )
}

export default CustomTextarea