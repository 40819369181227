import React, { useState } from 'react'
import { Modal, Box, Grid } from '@mui/material';
import CustomTextarea from './universal/CustomTextarea';
import CustomButton from './universal/CustomButton';



const AddNewRecipeModal = ({ openModal, handleClose, selectedStep, addStepAndUpdateNumbers }) => {

    const [itemVal, setItemVal] = useState();


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        maxWidth: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: 4,
        maxHeight: '90vh',
        display: 'flex',
    };


    const addItemHandler = () => {
        addStepAndUpdateNumbers(selectedStep,itemVal);
        handleClose();
    }


    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>

                    <Box sx={{ flex: 1, overflow: 'auto', }}>

                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <CustomTextarea
                                    fullWidth={true}
                                    height={60}
                                    value={itemVal}
                                    onChange={(event) => setItemVal(event.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <CustomButton
                                    fullWidth={true}
                                    text="Add"
                                    onClick={addItemHandler}
                                />
                            </Grid>
                        </Grid>

                    </Box>

                </Box>

            </Modal>
        </React.Fragment>
    )
}

export default AddNewRecipeModal