import React from 'react'
import { Modal, Box, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditFood from './EditFood';
import EditRecipe from './EditRecipe';
import EditIngr from './EditIngr';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { mainTheme_primary } from '../template/Colors';



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



const EditManager = ({ openModal, handleClose, selectedFoodId }) => {

    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };



    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        maxWidth: '95%',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        borderRadius: 4,
        maxHeight: '90vh',
        display: 'flex',
    };


    return (
        <React.Fragment>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >

                <Box sx={style}>

                    <Box sx={{ flex: 1, overflow: 'auto', }}>


                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Food Details" style={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'none' }} {...a11yProps(0)} />
                                <Tab label="Food Recipe" style={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'none' }} {...a11yProps(1)} />
                                <Tab label="Food ingredients" style={{ fontWeight: 'bold', fontSize: '16px', textTransform: 'none' }} {...a11yProps(2)} />
                            </Tabs>
                        </Box>


                        <Grid item xs={12}>
                            <Box sx={{ display: "flex", flexDirection: 'row', justifyContent: 'flex-end', marginTop: -6 }}>
                                <IconButton onClick={handleClose}>
                                    <CloseIcon sx={{ color: mainTheme_primary.gray }} />
                                </IconButton>
                            </Box>
                        </Grid>


                        <CustomTabPanel value={value} index={0}>
                            <EditFood selectedFoodId={selectedFoodId} />
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                            <EditRecipe selectedFoodId={selectedFoodId}  />
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={2}>
                            <EditIngr  selectedFoodId={selectedFoodId}/>
                        </CustomTabPanel>

                    </Box>

                </Box>

            </Modal>
        </React.Fragment>
    )
}

export default EditManager