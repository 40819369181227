import React, { useEffect, useState } from 'react'
import { send_post_request } from '../../utils/db';
import { AdminSearchFoodIngrUrl, AdminUpdateFoodIngUrl } from '../../config/Constants';
import { Box, Grid, IconButton, LinearProgress } from '@mui/material';
import { mainTheme_primary } from '../template/Colors';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import AddIcon from '@mui/icons-material/Add';
import CustomButton from './universal/CustomButton';
import AddNewIngModal from './AddNewIngModal';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));


const EditIngr = ({ selectedFoodId }) => {

    const [ingList, setIngList] = useState([]);
    const [unitList, setUnitList] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState();
    const [ingredients, setIngredients] = useState([]);
    const [loading, setLoading] = useState(false);

    const search_ing = async () => {
        setLoading(true);
        const data = {
            "guid": selectedFoodId
        }
        send_post_request(data, AdminSearchFoodIngrUrl, true).then(result => {
            setLoading(false)
            setIngList(result.result.ing_list);
            setUnitList(result.result.units);
            setIngredients(result.result.ingredients);
        })
    }

    useEffect(() => {
        search_ing();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const addHandler = (data) => {
        setEditModalOpen(true);
    }


    const addIngToList = (newItem) => {
        setIngList([...ingList, newItem]);
    };


    const removeIngFromList = (index) => {
        const updatedItems = [...ingList];
        updatedItems.splice(index, 1);
        setIngList(updatedItems);
    };


    const UpdateHandler = () => {
        const data = {
            "ing_list": ingList,
            "guid": selectedFoodId
        }


        send_post_request(data, AdminUpdateFoodIngUrl, true).then(result => {
            console.log(result);
        })

    }

    return (
        <Grid container spacing={2}>

            {loading &&
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            }

            {!loading &&
                <React.Fragment>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table" size='small'>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Row</StyledTableCell>
                                        <StyledTableCell>Ingr</StyledTableCell>
                                        <StyledTableCell align="left">Unit</StyledTableCell>
                                        <StyledTableCell align="left">Quantity</StyledTableCell>
                                        <StyledTableCell align="left">Delete</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ingList.map((row, index) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="left">{index + 1}</TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="left">{row.unit}</TableCell>
                                            <TableCell align="left">{row.quantity}</TableCell>
                                            <TableCell align="left">
                                                <IconButton onClick={() => removeIngFromList(index)}><DeleteIcon sx={{ color: mainTheme_primary.redMain }} /></IconButton>
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>


                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CustomButton
                                endIcon={<AddIcon />}
                                text="Add"
                                onClick={addHandler}
                            />
                        </Box>
                    </Grid>


                    <Grid item xs={12}>
                        <CustomButton
                            endIcon={<CloudUploadIcon />}
                            text="Upadte"
                            onClick={UpdateHandler}
                            fullWidth={true}
                        />
                    </Grid>
                </React.Fragment>
            }


            <AddNewIngModal
                openModal={editModalOpen}
                handleClose={() => setEditModalOpen(false)}
                unit_list={unitList}
                ingredients={ingredients}
                addIngToList={addIngToList}
            />
        </Grid>
    )
}

export default EditIngr

