import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Template from './Admin_pages/template/Template';

import Protected from './utils/Protected';

import MainHome from './pages/MainHome';

import Home from './Admin_pages/Home';
import Error from './Admin_pages/Error';
import Login from './Admin_pages/Login';
import ManageFoods from './Admin_pages/ManageFoods';
import ManageDataset from './Admin_pages/ManageDataset';
import BugReport from './Admin_pages/BugReport';


function App() {
  document.title = "CookifAI";


  return (

    <BrowserRouter>
      <Routes>
        {/* Main route */}
        <Route path="/" element={<MainHome />} />
        <Route path="/cookifAdminLogin" element={<Login />} />
        <Route path="*" element={<Error />} />

        {/* Protected routes under /admin */}
        <Route path="/panel" element={<Protected><Template /></Protected>}>
          <Route index element={<Home />} />
          <Route path="manageFoods" element={<ManageFoods />} />
          <Route path="manageDataset" element={<ManageDataset />} />
          <Route path="errorLogs" element={<BugReport />} />
          <Route path="*" element={<Error />} />
        </Route>


      </Routes>
    </BrowserRouter>

  )
}

export default App