import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { mainTheme_primary } from '../template/Colors';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));



const SearchFoodTable = ({ rows, editHandler }) => {
    return (
        <React.Fragment>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size='small'>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Row</StyledTableCell>
                            <StyledTableCell>Food name</StyledTableCell>
                            <StyledTableCell align="left">Archive</StyledTableCell>
                            <StyledTableCell align="left">Edit</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow
                                key={row.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{row.name}</TableCell>
                                <TableCell align="left">{row.archive ? "True" : "False"}</TableCell>
                                <TableCell align="left">
                                    <IconButton onClick={() => editHandler(row.guid)}>
                                        <EditIcon fontSize='small'  sx={{color:mainTheme_primary.greenMain}}/>
                                    </IconButton>
                                </TableCell>

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default SearchFoodTable