import { Box, Button, LinearProgress, TextField, Typography, Alert } from '@mui/material'
import React, { useState } from 'react'
import { mainTheme_primary } from './template/Colors';
import yourImageURL from '../statics/img/logo.png';
import { send_post_request } from '../utils/db';
import { AdminloginApiLink, LoginDuration } from '../config/Constants';
import { useNavigate } from 'react-router-dom';

const customSyle = {
  mainContainer: {
    height: '100vh',
    backgroundColor: mainTheme_primary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  loginContainer: {
    maxWidth: '95%',
    width: '500px',
    borderRadius: 5,
    border: 1,
    borderColor: mainTheme_primary.gray_border,
    backgroundColor: mainTheme_primary.white,
    padding: 1
  },
  loginBox: {
    backgroundColor: mainTheme_primary.white,
    padding: 2
  },
  logoTitle: {
    fontWeight: 900,
    fontSize: 30,
    textAlign: 'center',
  }
}
const Login = () => {

  const navigate = useNavigate()
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [wrongInfo, setWrongInfo] = useState(false);



  const handleSubmit = (e) => {
    e.preventDefault();

    setWrongInfo(false);


    const sign_in_data = {
      "username": username.toLowerCase(),
      "password": password
    }
    setLoading(true);


    send_post_request(sign_in_data, AdminloginApiLink, false).then(result => {
      setLoading(false);

      if (result.detail) {
        setWrongInfo(true);
      } else if (result.access) {


        var expire_time = new Date();
        expire_time.setMinutes(expire_time.getMinutes() + LoginDuration);

        localStorage.setItem("token", result.access);
        localStorage.setItem("expire_time", expire_time.getTime());


        navigate("/panel/");
      }

    });
  }

  return (
    <Box sx={customSyle.mainContainer}>
      <Box sx={customSyle.loginContainer}>
        <form onSubmit={(e) => handleSubmit(e)}>

          <Box sx={{ display: 'flex', justifyContent: "center" }}>
            <img
              src={yourImageURL}
              alt="Logo"
              style={{ objectFit: 'cover', width: "30%", borderRadius:20, marginTop:20 }}
            />
          </Box>


          <Box sx={customSyle.loginBox}>
            <Typography sx={customSyle.logoTitle}>Login</Typography>

            <Box sx={{ marginTop: 1 }}>
              <TextField
                fullWidth={true}
                label="Username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
              />
            </Box>

            <Box sx={{ marginTop: 2 }}>
              <TextField
                fullWidth={true}
                label="Password"
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                type='password'
              />
            </Box>

            {loading &&
              <Box sx={{ marginTop: 1 }}>
                <LinearProgress />
              </Box>
            }

            {wrongInfo && 
              <Alert sx={{marginTop:1}} severity='warning'>User or password is not correct</Alert>
            }

            <Box sx={{ marginTop: 2 }}>
              <Button fullWidth={true} type='submit' variant='contained' sx={{ textTransform: 'none' }}>Login</Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  )
}

export default Login