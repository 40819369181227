import React from 'react'
import { Autocomplete } from '@mui/material';


const CustomAutocomplete = ({ value, onChange, inputValue, onInputChange, filterOptions, options, renderOption, renderInput, multiple, limitTags, getOptionLabel, isOptionEqualToValue }) => {
    return (
        <Autocomplete
            multiple={multiple}
            value={value}
            onChange={onChange}
            inputValue={inputValue}
            onInputChange={onInputChange}
            options={options}
            renderOption={renderOption}
            renderInput={renderInput}
            limitTags={limitTags}
            getOptionLabel={getOptionLabel}
            filterOptions={filterOptions}
            isOptionEqualToValue={isOptionEqualToValue}

        />
    )
}

export default CustomAutocomplete