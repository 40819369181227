import { TextField } from '@mui/material'
import React from 'react'

const CustomTextField = ({label, fullWidth, value, onChange, type}) => {
    return (
        <React.Fragment>
            <TextField
                label={label}
                fullWidth={fullWidth}
                value={value}
                onChange={onChange}
                type={type}
            />
        </React.Fragment>
    )
}

export default CustomTextField