import { Grid, Typography, Box } from '@mui/material'
import React from 'react'
import logo from '../statics/assets/img/icon.png';
import '../statics/css/home.css';
import '../statics/css/responsive.css';


const MainHome = () => {
  return (
    <Box sx={CustomStyle.mainContainer}>
      <Grid container maxWidth='lg' sx={{ marginRight: 'auto', marginLeft: "auto" }}>
        <Box sx={CustomStyle.centerBox}>

          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img alt='logo' src={logo} style={{ width: 120, borderRadius: 15 }} className="imageStyle" />
            <Typography sx={{ fontSize: 35, color: "#fff", marginTop:2 }}>CookifAI</Typography>
            <Typography sx={{ fontSize: 30,lineHeight: "1.4", fontWeight:'300', color:"#fff", textAlign:'center', marginTop:5}}>Don't know what to cook? CookifAI has you covered!<br />Turn everyday ingredients into extraordinary meals. Simply tell us what you have in your kitchen, and let AI magic recommend delicious recipes tailored just for you.</Typography>
            <Typography sx={{ fontSize: 30, fontWeight:'300', color:"#fff", textAlign:'center'}}>Dinner dilemmas, meet your match!</Typography>
            <Typography sx={{ fontSize: 14,color:"#fff", textAlign:'center', marginTop:5}}>Copyright @ 2023 CookifAI. All rights reserved.</Typography>
          </Box>

        </Box>
      </Grid>
    </Box>
  )
}

export default MainHome

const CustomStyle = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    background: 'linear-gradient(0deg, rgb(147, 0, 0) 15%, rgb(232, 74, 0) 71%)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerBox: {
    display: 'flex',
    marginRight: 'auto',
    marginLeft: 'auto',
    padding: 1
  }
}