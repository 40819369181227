import { Box, Grid, IconButton, LinearProgress, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { send_post_request } from '../../utils/db';
import { AdminSearchFoodRecipeUrl, AdminUpdateFoodRecipieUrl } from '../../config/Constants';
import { mainTheme_primary } from '../template/Colors';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import AddNewRecipeModal from './AddNewRecipeModal';
import CustomButton from './universal/CustomButton';

const EditRecipe = ({ selectedFoodId }) => {

    const [recipelist, setRecipeList] = useState([]);
    const [addItemModalOpen, setAddItemModalOpen] = useState(false);
    const [selectedStep, setSelectedStep] = useState();
    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const readRecipe = async () => {
        setLoading(true);
        const data = { "guid": selectedFoodId }
        await send_post_request(data, AdminSearchFoodRecipeUrl, true).then(result => {
            setLoading(false);
            const rec_list = result.result.recipe_info;
            if (rec_list.length > 0){
                setRecipeList(rec_list);
            } else {
                const new_item = [{"step_number":1 ,"step_description":"add yours"}]
                setRecipeList(new_item);
            }
            
        })
    }



    const deleteStepAndUpdateNumbers = (stepNumberToDelete) => {
        // Filter out the step with the given step_number
        const updatedRecipeList = recipelist.filter(step => step.step_number !== stepNumberToDelete);

        // Update the step numbers for the remaining steps
        const updatedRecipeListWithNewNumbers = updatedRecipeList.map((step, index) => ({
            ...step,
            step_number: index + 1,
        }));

        // Update the state with the new recipe list
        setRecipeList(updatedRecipeListWithNewNumbers);
    };


    const addStepAndUpdateNumbers = (stepNumber, newItem) => {
        // Add a new item after the specified step_number
        const updatedRecipeList = recipelist.reduce((acc, step) => {
            acc.push(step);
            if (step.step_number === stepNumber) {
                acc.push({
                    step_number: stepNumber + 1,
                    step_description: newItem,
                });
            }
            return acc;
        }, []);

        // Update the step numbers for the remaining steps
        const updatedRecipeListWithNewNumbers = updatedRecipeList.map((step, index) => ({
            ...step,
            step_number: index + 1,
        }));

        // Update the state with the new recipe list
        setRecipeList(updatedRecipeListWithNewNumbers);
    };



    useEffect(() => {
        readRecipe();
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const deleteItemHandler = (step_number) => {
        deleteStepAndUpdateNumbers(step_number);
    }

    const addItemHandler = (step_number) => {
        setSelectedStep(step_number);
        setAddItemModalOpen(true);
    }



    const updateHanlder = () => {
        setUpdateLoading(true);
        const data = { "recipe_data": recipelist, "guid":selectedFoodId  }
        send_post_request(data, AdminUpdateFoodRecipieUrl, true).then(result => {
            setUpdateLoading(false);
        })
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>

                {loading &&
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                }

                {!loading &&
                    <React.Fragment>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: 2 }}>
                                {recipelist.map((item, index) => {
                                    return (
                                        <Box sx={ComponentStyle.recordContainer} >
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ paddingRight: 1, paddingLeft: 1 }}>
                                                    <Typography>{item.step_description}</Typography>
                                                </Box>

                                                <Box>
                                                    <Stack direction='row'>
                                                        <IconButton onClick={() => addItemHandler(item.step_number)}>
                                                            <AddIcon />
                                                        </IconButton>
                                                        <IconButton onClick={() => deleteItemHandler(item.step_number)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Stack>
                                                </Box>


                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>

                        </Grid>

                        {updateLoading &&
                            <Grid item xs={12}>
                                <LinearProgress />
                            </Grid>
                        }

                        <Grid item xs={12}>
                            <CustomButton
                                text='Update'
                                fullWidth={true}
                                onClick={updateHanlder}
                            />
                        </Grid>
                    </React.Fragment>
                }



                <AddNewRecipeModal
                    openModal={addItemModalOpen}
                    handleClose={() => setAddItemModalOpen(false)}
                    addStepAndUpdateNumbers={addStepAndUpdateNumbers}
                    selectedStep={selectedStep}
                />
            </Grid>
        </React.Fragment>
    )
}

export default EditRecipe


const ComponentStyle = {
    recordContainer: {
        border: 1,
        padding: 1,
        backgroundColor: mainTheme_primary.greenUltraLight,
        borderColor: mainTheme_primary.greenMain,
        borderRadius: 1
    }
}