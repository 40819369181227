// api constant
export const LoginDuration = 60;
// export const webUrl = "http://127.0.0.1:8000"
export const webUrl = "https://cookif-ai.com"

const mainEndpoint = "/api/main/"
export const ORIGIN_MEDIA = webUrl + "/media/";

export const AdminloginApiLink = webUrl + mainEndpoint + 'tokenWithPrivilege/'
export const AdminSearchFoodUrl = webUrl + mainEndpoint + 'admin_search_foods/'
export const AdminSearchFoodInfoUrl = webUrl + mainEndpoint + 'admin_search_foods_info/'
export const AdminSearchFoodRecipeUrl = webUrl + mainEndpoint + 'admin_search_foods_recipe/'
export const AdminSearchFoodIngrUrl = webUrl + mainEndpoint + 'admin_search_food_ingr/'
export const AdminUpdateFoodUrl = webUrl + mainEndpoint + 'admin_update_food/'
export const AdminUpdateFoodRecipieUrl = webUrl + mainEndpoint + 'admin_update_food_recipe/'
export const AdminUpdateFoodIngUrl = webUrl + mainEndpoint + 'admin_update_food_ing/'
export const AdminReadDatasetUrl = webUrl + mainEndpoint + 'admin_read_datasets/'
export const AdminAddDataset = webUrl + mainEndpoint + 'admin_add_dataset/'
export const AdminReadErrorLogs = webUrl + mainEndpoint + 'admin_read_error_logs/'
export const AdminDashboardStartupUrl = webUrl + mainEndpoint + 'admin_dashboard_startup/'

