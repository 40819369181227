import { Navigate } from "react-router-dom";
import {isAuthenticated} from './Authentication'


const Protected = ({ children }) => {

    const isLoggedIn = isAuthenticated();

    if (!isLoggedIn) {
        return <Navigate to="/" replace />;
    }
    return children;
};
export default Protected;