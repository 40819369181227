
export const isAuthenticated = () => {
    // read roken from browser
    const token = localStorage.getItem('token');

    // get expire time
    const expire_time = localStorage.getItem("expire_time");

    if (token) {
        //get time now
        var time_now = new Date();

        if (time_now.getTime() > expire_time) {
            localStorage.removeItem("token");
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export const LogoutFunction = () => {
    if (isAuthenticated()) {
        localStorage.removeItem('token');
        localStorage.removeItem('expire_time');
        return true;
    } else {
        return false
    }
}