import { Grid, LinearProgress, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import FastfoodIcon from '@mui/icons-material/Fastfood';
import { send_get_request } from '../utils/db';
import { AdminDashboardStartupUrl } from '../config/Constants';

const Home = () => {

  const [loading, setLoading] = useState(false);
  const [totalFoods, setTotalFoods] = useState(null);
  const [liveFoods, setLiveFoods] = useState(null);
  const [OffliceFoods, setOfflineFoods] = useState(null);
  
  const init_page = async () => {
    setLoading(true);
    await send_get_request(AdminDashboardStartupUrl).then(result => {
      setTotalFoods(result.result.total_foodst);
      setLiveFoods(result.result.live_foods);
      setOfflineFoods(result.result.offlice_foods);
      setLoading(false);
    })
  }

  useEffect(() => {
    init_page();
  }, [])


  const CardComponent = ({ customClass, title, value}) => {
    return (
      <Box sx={componenetStyle.boxContainer}>
        <Box sx={customClass}>
          <Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
            <FastfoodIcon sx={{ color: "#fff" }} />
            <Typography sx={{ fontSize: 16, color: "white", fontWeight: 900 }}>{title}</Typography>
          </Box>

          <Box sx={{ border: 1, color: "white" }}>
          </Box>

          <Box>
            <Typography sx={{ fontSize: 16, color: "white", fontWeight: 900, marginTop: 1 }}>{!loading ? value : <LinearProgress />}</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={4}>
        <CardComponent
          customClass={[componenetStyle.cards, componenetStyle.color1]}
          title="Total foods"
          value={totalFoods}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <CardComponent
          customClass={[componenetStyle.cards, componenetStyle.color2]}
          title="Live foods"
          value={liveFoods}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={4}>
        <CardComponent
          customClass={[componenetStyle.cards, componenetStyle.color3]}
          title="Offline foods"
          value={OffliceFoods}
        />
      </Grid>

      {/* <Grid item xs={12} sm={12} md={3}>
        <CardComponent
          customClass={[componenetStyle.cards, componenetStyle.color4]}
          title="Total Food"
        />
      </Grid> */}


    </Grid>
  )
}

export default Home


const componenetStyle = {
  boxContainer: {
    padding: 1,
  },
  cards: {
    padding: 1,
    borderRadius: 1,
    minHeight: 100,
    display:"flex",
    flexDirection: 'column',
    justifyContent: 'center',
  },
  color1: {
    backgroundImage: 'linear-gradient(to right, #f9905e, #fbb594)',
  },
  color2: {
    backgroundImage: 'linear-gradient(to right, #30c086, #3ff4a3)',
  },
  color3: {
    backgroundImage: 'linear-gradient(to right, #f95e74, #fa8f9f)',
  },
  color4: {
    backgroundImage: 'linear-gradient(to right, #2dabad, #3ddbdf)',
  }
}