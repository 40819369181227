import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, LinearProgress, Stack } from '@mui/material';
import { send_post_request, send_post_request_no_json } from '../../utils/db';
import { AdminSearchFoodInfoUrl, AdminUpdateFoodUrl, ORIGIN_MEDIA } from '../../config/Constants';
import CustomTextField from './universal/CustomTextField';
import CustomTextarea from './universal/CustomTextarea';
import CustomCheckbox from './universal/CustomCheckbox';
import CustomSelect from './universal/CustomSelect';
import CustomButton from './universal/CustomButton';
import CustomUploadBtn from './universal/CustomUploadBtn';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import noImage from '../../statics/img/noImage.jpeg';
import SnackComponent from './universal/SnackComponent';
import { mainTheme_primary } from '../template/Colors';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CropImage from './CropImage';
import { getCroppedImg } from '../../utils/cropImageFunction';

const EditFood = ({ selectedFoodId }) => {

    const [loading, setLoading] = useState(false);
    const [updateLoading, setUpdateLoadin] = useState(false);
    const [foodName, setFoodName] = useState('');
    const [foodDes, setFoodDes] = useState('');
    const [cooktime, setCooktime] = useState('');
    const [prepTime, setPrepTime] = useState('');
    const [difficaulty, setDifficaulty] = useState('');
    const [likes, setLikes] = useState('');
    const [foodArchive, setFoodArchive] = useState(false);
    const [foodImageUrl, setFoodImageUrl] = useState(null);
    const [foodImage, setFoodImage] = useState(null);
    const [snackComponent, setSnackComponent] = useState(false);
    const [failSnackComponent, setFailSnackComponent] = useState(false);
    const [datasetList, setDatasetList] = useState([]);
    const [dataset, setDataset] = useState('');
    const [foodImageStatus, setFoodImageStatus] = useState("not-change");
    const [difficultylist, setDifficultyList] = useState([]);



    const [currentPage, setCurrentPage] = useState('choose-img');



    const initModal = () => {
        setLoading(true);
        // read food info
        const data = { "guid": selectedFoodId }
        send_post_request(data, AdminSearchFoodInfoUrl, true).then(result => {
            setLoading(false);

            setDatasetList(result.result.dataset);
            setDifficultyList(result.result.difficulty);

            const data = result.result.food_info;

            setDataset(data.dataset_id)

            setFoodName(data.name);
            setFoodDes(data.description);
            setCooktime(data.cook_time);
            setPrepTime(data.preparation_time);
            setDifficaulty(data.difficulty);
            setLikes(data.like);
            setFoodArchive(data.archive);
            setFoodImage(data.image);
            if (data.image) {
                setFoodImageUrl(ORIGIN_MEDIA + data.image);
            }
        })
    }

    useEffect(() => {

        initModal();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const checkBoxHanlder = (event) => {
        setFoodArchive(event.target.checked);
    }

    const uploadHandler = (event) => {
        if (event.target.files && event.target.files[0]) {
            const image_file = event.target.files[0];
            setFoodImage(image_file);
            setFoodImageUrl(URL.createObjectURL(event.target.files[0]));
            setCurrentPage('crop-img');
            setFoodImageStatus('update');
        }
    }


    const removeImageHandler = () => {
        setFoodImageUrl(null);
        setFoodImage(null);
        setFoodImageStatus('removed');
    }

    const updateHandler = () => {

        setUpdateLoadin(true);
        const fd = new FormData();
        fd.append('food_guid', selectedFoodId);
        fd.append('food_name', foodName);
        fd.append('food_des', foodDes);
        fd.append('food_prep_time', prepTime);
        fd.append('food_cooking_time', cooktime);
        fd.append('food_difficaulty', difficaulty);
        fd.append('food_likes', likes);
        fd.append('food_archive', foodArchive);
        fd.append('food_image', foodImage);
        fd.append('dataset_id', dataset);
        fd.append('foodImageStatus', foodImageStatus);


        send_post_request_no_json(fd, AdminUpdateFoodUrl, true).then(result => {
            if (result.result) {
                setSnackComponent(true);
                setUpdateLoadin(false);
            } else {
                setFailSnackComponent(true);
            }

        })


    }


    const datasetHandler = (event) => {
        setDataset(event.target.value);
    }


    const onCropCancel = () => {}

    const onCropDone = async (imgCropedArea) => {
        // Assuming foodImage is the original image URL

        try {
            const croppedImageFile = await getCroppedImg(foodImage, imgCropedArea);
            setFoodImage(croppedImageFile);

            const croppedImageUrl = URL.createObjectURL(croppedImageFile);
            setFoodImageUrl(croppedImageUrl);
            setCurrentPage("choose-img");

        } catch (error) {
            console.error("Error cropping image:", error);
        }
    }


    const difficultyHandler = (event) => {
        setDifficaulty(event.target.value);
    }

    return (
        <React.Fragment>
            <Grid container spacing={3}>

                {loading &&
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                }

                {!loading &&
                    <React.Fragment>
                        {currentPage === 'choose-img' &&
                            <React.Fragment>
                                <Grid item xs={7}>
                                    <Box sx={{ display: 'flex', borderRadius: 3, backgroundColor: "#f2f2f2", justifyContent: 'center', alignItems: 'center', boxShadow: 2 }}>
                                        <img alt="food" src={foodImageUrl ? foodImageUrl : noImage} style={{ width: '300px' }} />
                                    </Box>
                                </Grid>

                                <Grid item xs={5}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <Stack direction='column' spacing={2}>
                                            <CustomUploadBtn
                                                text="Upload image"
                                                endIcon={<CloudUploadIcon />}
                                                onChange={uploadHandler}
                                            />
                                            <CustomButton
                                                text="Remove image"
                                                endIcon={<RemoveCircleIcon />}
                                                onClick={removeImageHandler}
                                                backgroundColor={mainTheme_primary.redMain}
                                                hoverColor={mainTheme_primary.redUltraLight}
                                            />
                                        </Stack>
                                    </Box>
                                </Grid>



                                <Grid item xs={12} sx={{ marginTop: 0 }}>
                                    <CustomSelect
                                        item_list={datasetList}
                                        label="dataset"
                                        handleChange={datasetHandler}
                                        value={dataset}
                                    />
                                </Grid>

                                <Grid item xs={12} sx={{ marginTop: 0 }}>
                                    <CustomTextField
                                        value={foodName}
                                        onChange={(event) => setFoodName(event.target.value)}
                                        label="Food name"
                                        fullWidth={true}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <CustomTextarea
                                        value={foodDes}
                                        onChange={(event) => setFoodDes(event.target.value)}
                                        label="Food description"
                                        fullWidth={true}
                                        height={100}
                                    />
                                </Grid>


                                <Grid item xs={4}>
                                    <CustomTextField
                                        value={prepTime}
                                        onChange={(event) => setPrepTime(event.target.value)}
                                        label="preperation time"
                                        fullWidth={true}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <CustomTextField
                                        value={cooktime}
                                        onChange={(event) => setCooktime(event.target.value)}
                                        label="cook time"
                                        fullWidth={true}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    {/* <CustomTextField
                                        value={difficaulty}
                                        onChange={(event) => setDifficaulty(event.target.value)}
                                        label="difficulty"
                                        fullWidth={true}
                                    /> */}

                                    <CustomSelect
                                        item_list={difficultylist}
                                        fullWidth={true}
                                        label="difficulty"
                                        value={difficaulty}
                                        handleChange={difficultyHandler}
                                    />
                                </Grid>



                                <Grid item xs={12}>
                                    <CustomTextField
                                        value={likes}
                                        onChange={(event) => setLikes(event.target.value)}
                                        label="Like number"
                                        fullWidth={true}
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <Box sx={{ marginLeft: -1, display: "flex", alignItems: 'center' }}>
                                        <CustomCheckbox
                                            checked={foodArchive}
                                            handler={checkBoxHanlder}
                                        />

                                        <Typography>Archive</Typography>
                                    </Box>


                                </Grid>

                                {updateLoading &&
                                    <Grid item xs={12}>
                                        <LinearProgress />
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <CustomButton
                                        text="update"
                                        endIcon={<CheckIcon />}
                                        fullWidth={true}
                                        onClick={updateHandler}
                                    />
                                </Grid>
                            </React.Fragment>

                        }

                        {currentPage === 'crop-img' &&
                            <Grid item xs={12}>
                                <CropImage
                                    image={foodImage}
                                    onCropDone={onCropDone}
                                    onCropCancel={onCropCancel}
                                />
                            </Grid>
                        }



                        <SnackComponent
                            open={snackComponent}
                            setOpen={setSnackComponent}
                            message="Updated"
                        />

                        <SnackComponent
                            open={failSnackComponent}
                            setOpen={setFailSnackComponent}
                            message="Faild"
                            severity='error'
                        />


                    </React.Fragment>
                }

            </Grid>
        </React.Fragment>
    )
}

export default EditFood