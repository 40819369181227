import React from 'react'
import { Box, Typography, Card,Grid, Avatar, Divider, Tooltip, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useNavigate } from 'react-router-dom';
import { LogoutFunction } from '../../../utils/Authentication';
import { mainTheme_primary } from '../Colors';
import { grey } from '@mui/material/colors';


const customStyle = {
    mainContainer: {
        padding: '0px !important',
        
    },
    mainCard: {
        backgroundColor: '#e6e6e6',
        padding: '20px !important',
        border: '1px solid #e0e0e0 !important',
        borderRadius: '0px',
    }
}




const SideMenuPofileCard = () => {

    const navigate = useNavigate();



    const logoutHandler = () => {
        LogoutFunction();
        // window.location.href('/radmanage/login');
        navigate('/');
    }



    return (
        <React.Fragment>
            <Box sx={customStyle.mainContainer}>
                <Card sx={{ width: "100%", boxShadow: 0, borderRadius: 0, paddingBottom: 2, backgroundColor:mainTheme_primary.primary }}>
                  

                    <Grid container>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                                <IconButton onClick={() => navigate('en/panel/home')}>
                                    <Avatar sx={{ width: 70, height: 70, border: 4, borderColor: '#fff' }}/>
                                </IconButton>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 1 }}>
                                <Typography sx={{fontSize:'16px', color:mainTheme_primary.seccondary, fontWeight:600}} >Admin</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 0 }}>
                                <Typography sx={{fontSize:'14px',color:mainTheme_primary.greenMain}}>mojtab.gh@gmail.com</Typography>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 2, gap: 2 }}>
                                <Tooltip title='setting'>
                                    <IconButton onClick={() => navigate('en/panel/setting')}>
                                        <SettingsIcon sx={{ color: grey[400] }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title='log out'>
                                    <IconButton onClick={logoutHandler}>
                                        <ExitToAppIcon sx={{ color: grey[400] }} />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Grid>

                        <Grid item xs={12} sx={{ marginTop: 2 }}>
                            <Divider />
                        </Grid>
                    </Grid>
                </Card>
            </Box>
        </React.Fragment>
    )
}

export default SideMenuPofileCard