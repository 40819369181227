import { Grid, Box, Stack, Pagination, LinearProgress } from '@mui/material'
import React, { useState } from 'react'
import CustomTextField from './components/universal/CustomTextField'
import CustomButton from './components/universal/CustomButton'
import SearchIcon from '@mui/icons-material/Search';
import { AdminSearchFoodUrl } from '../config/Constants';
import { send_post_request } from '../utils/db';
import SearchFoodTable from './components/SearchFoodTable';
import EditManager from './components/EditManager';
import CustomSelect from './components/universal/CustomSelect';
import ClearIcon from '@mui/icons-material/Clear';


const SEARCH_NUMBER = 10;
const STATUS_FILTER_OPTION = [{ "id": 1, "name": "live" }, { "id": 2, "name": "offline" }, { "id": 3, "name": "All" }];

const ManageFoods = () => {

  const [keyword, setKeyword] = useState('');
  const [resultList, setResultList] = useState([]);
  // const [offset, setOffset] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedFoodId, setSelectedFoodId] = useState('');
  const [searching, setSearching] = useState(false);
  const [liveFilter, setLiveFilter] = useState(3);

  const searchFood = async (offset) => {
    setSearching(true);
    const data = {
      "keyword": keyword,
      "offset": offset,
      "number": SEARCH_NUMBER,
      "filter": liveFilter
    }

    await send_post_request(data, AdminSearchFoodUrl, true).then(result => {
      setSearching(false);
      setResultList(result.result.food_list);
      setTotalRows(Math.ceil(result.result.rows / SEARCH_NUMBER));
    })
  }

  const searchHandler = () => {
    searchFood(0);
  }


  const paginationHandler = (event, val) => {
    // setOffset(val - 1);
    searchFood((val - 1) * SEARCH_NUMBER);
  }


  const openEditModalHandler = (foodGuid) => {
    setSelectedFoodId(foodGuid);
    setEditModalOpen(true);
  }



  const liveFilterHandler = (event) => {
    setLiveFilter(event.target.value);
  }


  return (
    <Grid container maxWidth='lg' sx={{ marginRight: "auto", marginLeft: "auto" }} spacing={2}>
      <Grid item xs={12}>
        <CustomTextField
          fullWidth={true}
          label="Search food"
          value={keyword}
          onChange={(event) => setKeyword(event.target.value)}
        />
      </Grid>


      <Grid item xs={12} md={3}>
        <CustomSelect
          item_list={STATUS_FILTER_OPTION}
          label="food status"
          value={liveFilter}
          handleChange={liveFilterHandler}
        />
      </Grid>


      



      <Grid item xs={12}>
        <Box sx={{display:"flex", gap:1}}>
        <CustomButton onClick={searchHandler} text="search" endIcon={<SearchIcon />} />
        <CustomButton 
          text="Clear filters"
          endIcon={<ClearIcon />}
          onClick={() => setLiveFilter(3)}
        />
        </Box>
      </Grid>

      {searching &&
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      }

      {resultList.length > 0 &&
        <Grid item xs={12}>
          <SearchFoodTable rows={resultList} editHandler={openEditModalHandler} />

          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
            <Stack spacing={2} direction='row'>
              <Pagination count={totalRows} onChange={(event, val) => paginationHandler(event, val)} variant="outlined" shape="rounded" />
            </Stack>
          </Box>
        </Grid>
      }


      <EditManager
        openModal={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        selectedFoodId={selectedFoodId}
      />

    </Grid>
  )
}

export default ManageFoods