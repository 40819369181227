import { mainTheme_primary } from "../../template/Colors"
import { Button } from "@mui/material"

const CustomUploadBtn = ({ fullWidth, startIcon, endIcon, text, color, backgroundColor, hoverColor, fileInputRef, onChange, id }) => {

    return (
        <Button
            variant="contained"
            fullWidth={fullWidth}
            startIcon={startIcon}
            endIcon={endIcon}
            sx={{
                textTransform: 'none',
                color: color ? color : mainTheme_primary.white,
                backgroundColor: backgroundColor ? backgroundColor : mainTheme_primary.main,
                '&:hover': { backgroundColor: hoverColor ? hoverColor : mainTheme_primary.greenMain }
            }}
            component="label"
        >
            <input id={id} ref={fileInputRef} onChange={onChange} accept="image/*" type="file" hidden />
            {text}
        </Button>
    )
}

export default CustomUploadBtn