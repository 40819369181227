import React from 'react'
import { Checkbox } from '@mui/material'

const CustomCheckbox = ({ checked, handler }) => {

    return (
        <Checkbox
            checked={checked}
            onChange={handler}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
        />
    )
}

export default CustomCheckbox