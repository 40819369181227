import React from 'react'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const CustomSelect = ({item_list, handleChange, label , value}) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{label}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={value}
                label={label}
                onChange={handleChange}
            >
                
                {item_list.map((item,index) => {
                    return(
                        <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                    )
                })}

            </Select>
        </FormControl>
    )
}

export default CustomSelect