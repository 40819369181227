import React from 'react'
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { mainTheme_primary } from '../Colors';
import { Link } from 'react-router-dom';
import { Box, Badge, Collapse } from '@mui/material';
import SideMenuPofileCard from './SideMenuPofileCard';
import RestaurantMenuIcon from '@mui/icons-material/RestaurantMenu';
import StorageIcon from '@mui/icons-material/Storage';
import BugReportIcon from '@mui/icons-material/BugReport';

const MenuList = ({ setOpen }) => {


    const SubListComponent = ({ url, icon, label, nitification }) => {

        const closeSideMenu = () => {
            const innerWidth = window.innerWidth;
            if (innerWidth < 1010) {
                setOpen(false);
            }
        }

        return (
            <Link to={url} onClick={closeSideMenu} style={{ textDecoration: "none", color: "#000" }}>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon sx={{ color: mainTheme_primary.seccondary }}>
                            {icon}
                        </ListItemIcon>
                        <Badge badgeContent={nitification} sx={{ color: mainTheme_primary.seccondary }}>
                            <ListItemText
                                disableTypography
                                primary={label}
                            />
                        </Badge>
                    </ListItemButton>
                </List>
            </Link>
        )
    }



    return (
        <Box sx={{ display: "flex", flexDirection: 'column', justifyContent: 'space-between', height: '100%', backgroundColor: mainTheme_primary.primary }}>

            <Box sx={{ flex: 1 }}>

                <SideMenuPofileCard />

                <Box style={{ textDecoration: "none" }}>
                    <ListItemButton>
                        <ListItemText
                            primary="Admin Menu"
                            sx={{ color: mainTheme_primary.white }}
                            disableTypography
                        />
                    </ListItemButton>

                    <Collapse in={true} timeout="auto" unmountOnExit>

                        <SubListComponent
                            url='/panel/manageFoods'
                            icon={<RestaurantMenuIcon />}
                            label="Manage Foods"
                        />


                        <SubListComponent
                            url='/panel/manageDataset'
                            icon={<StorageIcon />}
                            label="Manage Dataset"
                        />


                        <SubListComponent
                            url='/panel/errorLogs'
                            icon={<BugReportIcon />}
                            label="Bug Report"
                        />

                    </Collapse>

                    {/* 
                    <Box style={{ textDecoration: "none", color: "#000" }}>
                        <ListItemButton onClick={() => setSupportMenu(!supportMenu)} sx={{ color: primary.main }}>
                            <ListItemText
                                className={classes.sideMenuText}
                                disableTypography
                                primary={strings.Support}
                            />
                            {supportMenu ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={supportMenu} timeout="auto" unmountOnExit>

                            <SubListComponent
                                url='/en/panel/help'
                                icon={<HelpCenterIcon />}
                                label={strings.Help}
                            />

                        </Collapse>
                    </Box> */}





                </Box>


            </Box>

            <Box style={{ textDecoration: "none", color: "#000" }}>
                {/* <ReadVersion /> */}
            </Box>

        </Box>
    )
}

export default MenuList