export const send_post_request = (data, link, need_token) => {
    if (need_token) {
        const token = localStorage.token;
        return fetch(link, {
            method: 'Post',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + token
            },
            body: JSON.stringify(data)
        })
            .then(result => {
                if (result.status === 401) {
                    localStorage.removeItem('token')
                    window.location.href = '/'
                } else if (result.status === 403) {
                    window.location.href = '/'
                }

                return (result.json())
            })
    }
    else {
        return fetch(link, {
            method: 'Post',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
            .then(result => result.json())
    }
}


export const send_get_request = (link) => {
    const token = localStorage.token;
    return fetch(link, {
        method: 'Get',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
        },
    })
        .then(result => {
            if (result.status === 401) {
                localStorage.removeItem('token')
                window.location.href = '/'
            } else if (result.status === 403) {
                window.location.href = '/'
            }
            return (result.json())
        })
}



export const send_post_request_no_json = (data, link, need_token) => {
    if (need_token) {
        const token = localStorage.token;
        return fetch(link, {
            method: 'Post',
            headers: {
                "Accept": "application/json",
                "Authorization": "Bearer " + token
            },
            body: data
        })
            .then(result => {
                if (result.status === 401) {
                    window.location.href = '/'
                }
                return (result.json())
            })
    }
    else {
        return fetch(link, {
            method: 'Post',
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            body: data
        })
            .then(result => result.json())
    }
}